<template>
  <div id="NotebookDashboard">

    <a-button class="button" type="primary" @click="this.newNotebook()">
      New
    </a-button>

    <a-popconfirm
        title="Are you sure delete this notebook?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="removeNotebook"
    >
      <a-button class="button" id="remove" type="primary">
        Remove
      </a-button>
    </a-popconfirm>

    <a-button class="button" type="primary" @click="this.importNotebook()">
      Import
    </a-button>

    <a-button class="button" type="primary" @click="this.importTemplate()">
      Templates
    </a-button>

    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>

    <ag-grid-vue
        id="table"
        style=" height: 700px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="notebooks"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-modal
        v-model:visible="newNotebookVisible"
        :closable=false
        title="New Notebook"
        @ok="okNewNotebook">
      <a-form
          v-model:model="formNotebook"
          name="form_notebook"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Name">
          <a-input v-model:value="formNotebook['name']" />
        </a-form-item>

        <a-form-item label="Cluster">
          <a-select
              v-model:value="formNotebook['cluster']"
              style="width: 150px"
              :options="storeClusters.clustersOptions"
          ></a-select>
        </a-form-item>

      </a-form>
    </a-modal>

    <a-modal
        v-model:visible="importNotebookVisible"
        :closable=false
        title="Import Notebook"
        @ok="okImportNotebook">
      <a-form
          v-model:model="formImportNotebook"
          name="form_import_notebook"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="User">
          <a-select
              v-model:value="formImportNotebook['user']"
              show-search
              allowClear

              style="width: 200px"
              :options="storeUsers.users"
              :filter-option="filterOption"
              @change="getNotebooksFromUser"
          ></a-select>
        </a-form-item>

        <a-form-item label="Notebook">
          <a-select
              v-model:value="formImportNotebook['notebook']"
              show-search
              allowClear
              :filter-option="filterOption"
              style="width: 150px"
              :options="notebooksFromUser"
          ></a-select>
        </a-form-item>


        <a-form-item label="Name">
          <a-input v-model:value="formImportNotebook['name']" />
        </a-form-item>

        <a-form-item label="Cluster">
          <a-select
              v-model:value="formImportNotebook['cluster']"
              style="width: 150px"
              :options="storeClusters.clustersOptions"
          ></a-select>
        </a-form-item>

      </a-form>
    </a-modal>

    <a-modal
        v-model:visible="importTemplateVisible"
        :closable=false
        title="Importe Template"
        @ok="okImportTemplate">
      <a-form
          v-model:model="formImportTemplate"
          name="form_import_template"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Notebook">
          <a-select
              v-model:value="formImportTemplate['notebook']"
              show-search
              allowClear
              :filter-option="filterOption"
              style="width: 150px"
              :options="storeTemplates.templatesOptions"
          ></a-select>
        </a-form-item>


        <a-form-item label="Name">
          <a-input v-model:value="formImportTemplate['name']" />
        </a-form-item>

        <a-form-item label="Cluster">
          <a-select
              v-model:value="formImportTemplate['cluster']"
              style="width: 150px"
              :options="storeClusters.clustersOptions"
          ></a-select>
        </a-form-item>

      </a-form>
    </a-modal>

  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {apiUrl, TLSEnabled, zeppelinApi} from "@/config/api";
import {reactive} from "vue";
import { notification } from 'ant-design-vue';
import {clusterStore} from "@/store/ClusterStore";
import {userStore} from "@/store/UserStore";
import {getClusters, getTemplates, getUsers} from "@/helpers/RefreshQueries";
import {templateStore} from "@/store/TemplateStore";

export default {
  components: {
    AgGridVue
  },
  props: ['keycloak','isActive'],
  setup() {
    const storeClusters = clusterStore()
    const storeUsers = userStore()
    const storeTemplates= templateStore()
    const filterOption = (input, option) => {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {storeClusters, storeUsers, storeTemplates, filterOption}
  },
  data() {
    return {
      notebooks: [],
      notebooksFromUser: [],
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        onCellDoubleClicked: this.onCellDoubleClicked,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [
        {
          headerName: 'name',
          width: 300,
          field: 'name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
          checkboxSelection: true
        },
        {
          headerName: 'cluster',
          width: 300,
          field: 'cluster',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }
      ],
      formNotebook: reactive({}),
      formImportNotebook: reactive({}),
      formImportTemplate: reactive({}),
      roles: [],
      newNotebookVisible: false,
      importNotebookVisible: false,
      importTemplateVisible: false
    }
  },
  created() {
    this.refreshQuery()
  },
  methods: {

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    removeNotebook() {
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes) {
        const node = selectedNodes[0]
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/notebooks/" + node.data.id
        const token = "Bearer " + this.keycloak.token
        fetch(uri, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (response.ok) {
                this.refreshQuery()
              }
              else {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
            })
            .catch(error => {
              this.errorNotification(error)
            })
      }
    },
    importNotebook() {
      this.importNotebookVisible = true
    },
    importTemplate() {
      this.importTemplateVisible = true
    },
    getNotebooks() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/notebooks/users/" + this.keycloak.subject
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.notebooks = responseJson
          })
          .catch((error) => {
            console.log(error)
          })
    },
    refreshQuery() {
      getClusters(this.keycloak.token)
      getUsers(this.keycloak.token)
      getTemplates(this.keycloak.token)
      this.getNotebooks()
    },
    newNotebook() {
      this.formNotebook.name = ""
      this.newNotebookVisible = true
    },
    onCellDoubleClicked(event) {
      const uri = "https" + "://" + zeppelinApi + "/#/notebook/" + event.data.id
      window.open(uri, '_blank');

    },
    errorNotification(msg) {
      console.log(msg)
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    },
    getNotebooksFromUser(user) {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/notebooks/users/" + user
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.formImportNotebook.notebook = []
            this.notebooksFromUser = []
            responseJson.forEach(notebook =>{
              this.notebooksFromUser.push({value:notebook.id,label:notebook.name})
            })
          })
          .catch((error) => {
            console.log(error)
          })
    },
    cloneNotebook(id, name, cluster) {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/notebooks/clone/" + id
      const token = "Bearer " + this.keycloak.token
      const cloneNotebook = {
        name: name,
        path: "users/" + this.keycloak.subject,
        cluster: cluster
      }
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(cloneNotebook),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then(async response => {
            if (!response.ok) {
              const error = (await response.text() || response.statusText)
              return Promise.reject(error)
            }
            else {
              this.refreshQuery()
            }

          })
          .catch(error => {
            this.errorNotification(error)
          })
    },
    okImportNotebook() {
      this.cloneNotebook(this.formImportNotebook.notebook, this.formImportNotebook.name, this.formImportNotebook.cluster)
      this.formImportNotebook.notebook = ""
      this.formImportNotebook.name = ""
      this.formImportNotebook.user = ""
      this.formImportNotebook.cluster = ""
      this.importNotebookVisible = false
    },
    okImportTemplate() {
      this.cloneNotebook(this.formImportTemplate.notebook, this.formImportTemplate.name, this.formImportTemplate.cluster)
      this.formImportTemplate.notebook = ""
      this.formImportTemplate.name = ""
      this.formImportTemplate.cluster = ""
      this.importTemplateVisible = false
    },
    okNewNotebook() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/notebooks"
      const token = "Bearer " + this.keycloak.token
      const newNotebook = {
        name: this.formNotebook.name,
        path: "users/" + this.keycloak.subject,
        cluster: this.formNotebook.cluster
      }
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(newNotebook),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
        .then(async response => {
          if (!response.ok) {
            const error = (await response.text() || response.statusText)
            return Promise.reject(error)
          }
          else {
            this.refreshQuery()
          }

        })
        .catch(error => {
          this.errorNotification(error)
        })
      this.newNotebookVisible = false
  }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>