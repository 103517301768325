<template>
  <div id="ClusterDashboard">

    <a-button class="button" type="primary" @click="this.newCluster()">
      New
    </a-button>
    <a-popconfirm
        title="Are you sure restart this cluster?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="restartCluster"
    >
      <a-button class="button" id="restart" type="primary">
        Restart
      </a-button>
    </a-popconfirm>
    <a-popconfirm
        title="Are you sure delete this cluster?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="removeCluster"
    >
      <a-button class="button" id="remove" type="primary">
        Remove
      </a-button>
    </a-popconfirm>
    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>

    <ag-grid-vue
        id="cluster_table"
        style=" height: 700px"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="store.clusters"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-modal
        v-model:visible="newClusterVisible"
        :closable=false
        title="New Cluster"
        @ok="okNewCluster">
      <a-form
          v-model:model="formCluster"
          name="form_cluster"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Name">
          <a-input v-model:value="formCluster['name']" />
        </a-form-item>

        <a-form-item name="mode" label="Mode">
          <a-select
              v-model:value="formCluster['mode']"
              style="width: 150px"
              :options="modeOptions"
          ></a-select>
        </a-form-item>

        <a-form-item label="Driver Cores">
          <a-input v-model:value="formCluster['driverCores']" />
        </a-form-item>

        <a-form-item label="Driver Memory">
          <a-input v-model:value="formCluster['driverMemory']" />
        </a-form-item>

        <a-form-item label="Executors">
          <a-input v-model:value="formCluster['executors']" />
        </a-form-item>

        <a-form-item label="Executor Cores">
          <a-input v-model:value="formCluster['executorCores']" />
        </a-form-item>

        <a-form-item label="Executor Memory">
          <a-input v-model:value="formCluster['executorMemory']" />
        </a-form-item>

        <a-form-item label="Max Result">
          <a-input v-model:value="formCluster['maxResults']" />
        </a-form-item>

        <a-form-item label="Read Only">
          <a-switch v-model:checked="formCluster.readonly" />
        </a-form-item>

      </a-form>
    </a-modal>

  </div>

</template>

<script>

import {apiUrl, TLSEnabled} from "@/config/api";
import {reactive} from "vue";
import { notification } from 'ant-design-vue';
import {clusterStore} from "@/store/ClusterStore";
import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {getClusters} from "@/helpers/RefreshQueries";



export default {
  components: {
    AgGridVue
  },
  props: ['keycloak','isActive'],
  setup() {
    const store= clusterStore()
    return {store}
  },
  data() {
    return {
      modeOptions: [
        {value:'SINGLE',label:'SINGLE'},
        {value:'CLUSTER',label:'CLUSTER'}
      ],
      formCluster: reactive({}),
      roles: this.keycloak.realmAccess.roles,
      newClusterVisible: false,
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        getRowId: (item) => {
          return item.data.name;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [

        {
          headerName: 'name',
          width: 300,
          field: 'name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
          checkboxSelection: true
        },
        {
          headerName: 'mode',
          width: 100,
          field: 'mode',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Driver Cores',
          width: 130,
          field: 'driverCores',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Driver Memory',
          width: 150,
          field: 'driverMemory',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Executors',
          width: 120,
          field: 'executors',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Executor Cores',
          width: 150,
          field: 'executorCores',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Executor Memory',
          width: 170,
          field: 'executorMemory',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Max Results',
          width: 150,
          field: 'maxResults',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Read Only',
          width: 140,
          field: 'readonly',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }
      ]
    }
  },
  created() {
    this.refreshQuery()
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    removeCluster() {
      if (this.roles.includes("zeppelin_admin")) {
        const selectedNodes = this.gridApi.getSelectedNodes()
        if (selectedNodes) {
          const node = selectedNodes[0]
          const tls = (TLSEnabled) ? "s" : ""
          const uri = "http" + tls + "://" + apiUrl + "/clusters/" + node.data.id
          const token = "Bearer " + this.keycloak.token
          fetch(uri, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              "Authorization": token
            }
          })
              .then(async response => {
                if (response.ok) {
                  this.refreshQuery()
                } else {
                  const error = (await response.text() || response.statusText)
                  return Promise.reject(error)
                }
              })
              .catch(error => {
                this.errorNotification(error)
              })
        }
      }
      else
        this.errorNotification("Not authorized")
    },
    restartCluster() {
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes) {
        const node = selectedNodes[0]
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/clusters/restart"
        const token = "Bearer " + this.keycloak.token
        const clusterId = {id: node.data.id}
        fetch(uri, {
          method: 'PUT',
          body: JSON.stringify(clusterId),
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (response.ok) {
                this.msgNotification("Restart successfully")
              }
              else {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
            })
            .catch(error => {
              this.errorNotification(error)
            })
      }
    },
    refreshQuery() {
      getClusters(this.keycloak.token)
    },
    newCluster() {
      if (this.roles.includes("zeppelin_admin")) {
        this.formCluster.mode = "SINGLE"
        this.formCluster.driverCores = "1"
        this.formCluster.driverMemory = "1G"
        this.formCluster.executorCores = ""
        this.formCluster.executorMemory = ""
        this.formCluster.executors = ""
        this.formCluster.maxResults = "1000"
        this.formCluster.readonly = false
        this.newClusterVisible = true
      }
      else
        this.errorNotification("Not authorized")
    },
    errorNotification(msg) {
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    },
    msgNotification(msg) {
      notification.open({
        message: 'Message',
        description: msg,
        class: 'success-class',
      });
    },
    okNewCluster() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/clusters"
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(this.formCluster),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
        .then(async response => {
          if (!response.ok) {
            const error = (await response.text() || response.statusText)
            return Promise.reject(error)
          }
          else
            this.refreshQuery()
        })
        .catch(error => {
          this.errorNotification(error)
        })
      this.newClusterVisible = false
  }
  }

}
</script>

<style>



#cluster_table {
  margin-top: 20px;
}

#logout {
  float: right;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>