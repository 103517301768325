<template>
  <div id="TestigoDashboard">
    <a-form
        v-model:model="corporation"
        name="corporation"
        :wrapper-col="{ span: 2 }"
    >
      <a-form-item label="Corporation">
        <a-input v-model:value="corporation['id']" />
      </a-form-item>
    </a-form>

    <a-form layout="inline">
      <a-form-item>
        <a-upload
            :showUploadList="false"
            :accept="formats"
            :action="getUploadSaldosFile"
            :headers="headers"
            :before-upload="beforeUpload"
            @change="handleChange"
        >
          <a-button>
            <upload-outlined></upload-outlined>
            Saldos Abiertos
          </a-button>
        </a-upload>
      </a-form-item>
      <a-form-item>
          <a-upload
            :showUploadList="false"
            :accept="formats"
            :action="getUploadFacturacionFile"
            :headers="headers"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
          <a-button>
            <upload-outlined></upload-outlined>
            Facturación
          </a-button>
        </a-upload>
      </a-form-item>  
      <a-form-item>
        <a-input v-model:value="tableTestigoLibre['tableName']" placeholder="table name" required/>
      </a-form-item>
      
      <a-form-item>
        <a-upload
            :showUploadList="false"
            :accept="formats"
            :action="getUploadTestigoLibreFile"
            :headers="headers"
            :before-upload="beforeUploadTestigo"
            @change="handleChange"
            >
            <a-button>
              <upload-outlined></upload-outlined>
              Testigo Libre
            </a-button>
          </a-upload>
      </a-form-item>

      <a-form-item>
        <a-button class="button" type="primary" @click="this.refreshQuery()">
          Refresh
        </a-button>
      </a-form-item>

      <a-form-item>
        <a-switch v-model:checked="refresh"  @change="this.refreshAuto()"/>
      </a-form-item>
      
      <a-spin :indicator="indicatorRefresh" :spinning="spinning"/>
    </a-form>

    <ag-grid-vue
        id="table"
        style=" height: 700px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="tasks"
        :getRowStyle="getRowStyle"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { LoadingOutlined } from '@ant-design/icons-vue';
import {persistorApi, TLSEnabled} from "@/config/api";
import { notification } from 'ant-design-vue';
import {reactive} from "vue";
import { h } from 'vue';

export default {
  components: {
    AgGridVue,
    UploadOutlined
  },
  props: ['keycloak','isActive'],
  setup() {

  },
  data() {
    return {
      formats: ".csv",
      refresh: true,
      spinning: true,
      refreshTimer: null,
      indicatorRefresh: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      }),
      corporation: reactive({}),
      tableTestigoLibre: reactive({}),
      headers: {
        authorization: "Bearer " + this.keycloak.token,
      },
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: false,
        paginationAutoPageSize: false,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [
        {
          headerName: 'Date',
          width: 200,
          field: 'created',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'Database',
          width: 150,
          field: 'database',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Table',
          width: 250,
          field: 'table',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Status',
          width: 100,
          field: 'status',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Message',
          width: 600,
          field: 'msg',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }
      ],
      roles: [],
      tasks: [],
      optionsUri: "?saveMode=Overwrite&options=timestampFormat=dd/MM/yyyy HH:mm:ss,locale=es-ES,header=true,delimiter=;",
      optionsUriTestigoLibre: "?saveMode=Overwrite&createTable=true&options=timestampFormat=dd/MM/yyyy HH:mm:ss,locale=es-ES,header=true,delimiter=;",
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setTimer()
        } else {
          this.cancelTimer()
        }
      },
    },
  },
  created() {
    this.refreshQuery()
    this.setTimer()
  },
  methods: {
    setTimer() {
      if (this.refreshTimer == null) {
        this.refreshTimer = setInterval(this.refreshQuery, 10000)
        this.spinning = true
      }
    },
    cancelTimer() {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
      this.spinning = false
    },
    refreshAuto() {
      if (this.refresh) 
        this.setTimer()
      else
        this.cancelTimer()
    },
    beforeUpload(){
      if (this.corporation.id === '' || this.corporation.id === null || this.corporation.id === undefined ) {
        message.error('corporation id is required');
        return(false)
      }
    },
    beforeUploadTestigo(){
      if (this.corporation.id === '' || this.corporation.id === null || this.corporation.id === undefined ) {
        message.error('corporation id is required');
        return(false)
      }

      if (this.tableTestigoLibre.tableName === '' || this.tableTestigoLibre.tableName === null || this.tableTestigoLibre.tableName === undefined ) {
        message.error('table name is required');
        return(false)
      }
    },
    getUploadSaldosFile() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/stores/intdb_" + this.corporation.id  + "/tables/testigos_saldo_abierto" + this.optionsUri
      return uri
    },
    getUploadFacturacionFile() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/stores/intdb_" + this.corporation.id  + "/tables/testigos_facturacion"  + this.optionsUri
      return uri
    },
    getUploadTestigoLibreFile() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/stores/intdb_" + this.corporation.id  + "/tables/testigos_" + this.tableTestigoLibre.tableName + this.optionsUriTestigoLibre
      return uri
    },
    handleChange(info) {
      if (info.file.status === 'done') {
        setTimeout(() => {
          this.refreshQuery()
        }, 1000);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.error.message}. Upload failed.`);
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'desc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    getRowStyle: (params) => {
      switch (params.data.status) {
        case "FAIL":
          return {'color': 'red'}
        case "DONE":
          return {'color': 'green'}
        default:
          return {'color': 'blue'}
      }
    },
    refreshQuery() {
      if (!this.refresh)
        this.cancelTimer()
      const token = this.keycloak.token
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/tasks/users/" + this.keycloak.subject + "/task/insert_from_file?limit=20"
      const tokenBearer = "Bearer " + token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": tokenBearer
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.tasks = []
            responseJson.results.forEach(task =>{
              const payload = JSON.parse(task.payload)
              this.tasks.push({
                id: task.id,
                created: task.created,
                database: payload.identifier.database,
                table: payload.identifier.table,
                status: task.status,
                msg: task.msg
              })
            })

          })
          .catch((error) => {
            console.log(error)
          })

    },
    errorNotification(msg) {
      console.log(msg)
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
}

.ant-btn {
  color: #fff !important;
  border-color: #40a9ff !important;
  background: #40a9ff !important;
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>