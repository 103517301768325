<template>
  <div id="TemplateDashboard">

    <a-button class="button" type="primary" @click="this.newTemplate()">
      New
    </a-button>

    <a-popconfirm
        title="Are you sure delete this notebook?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="removeTemplate"
    >
      <a-button class="button" id="remove" type="primary">
        Remove
      </a-button>
    </a-popconfirm>

    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>

    <ag-grid-vue
        id="table"
        style=" height: 700px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="storeTemplates.templates"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-modal
        v-model:visible="newTemplateVisible"
        :closable=false
        title="New Template"
        @ok="okNewTemplate">
      <a-form
          v-model:model="formTemplate"
          name="form_template"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Name">
          <a-input v-model:value="formTemplate['name']" />
        </a-form-item>

        <a-form-item label="Cluster">
          <a-select
              v-model:value="formTemplate['cluster']"
              style="width: 150px"
              :options="storeClusters.clustersOptions"
          ></a-select>
        </a-form-item>

      </a-form>
    </a-modal>

  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {apiUrl, TLSEnabled, zeppelinApi} from "@/config/api";
import {reactive} from "vue";
import { notification } from 'ant-design-vue';
import {clusterStore} from "@/store/ClusterStore";
import {getClusters, getTemplates} from "@/helpers/RefreshQueries";
import {templateStore} from "@/store/TemplateStore";

export default {
  components: {
    AgGridVue
  },
  props: ['keycloak','isActive'],
  setup() {
    const storeClusters = clusterStore()
    const storeTemplates = templateStore()
    return {storeClusters, storeTemplates}
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: true,
        paginationAutoPageSize: true,
        onCellDoubleClicked: this.onCellDoubleClicked,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [

        {
          headerName: 'name',
          width: 300,
          field: 'name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
          checkboxSelection: true
        },
        {
          headerName: 'cluster',
          width: 300,
          field: 'cluster',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }
      ],
      formTemplate: reactive({}),
      roles: [],
      newTemplateVisible: false
    }
  },
  created() {
    this.refreshQuery()
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    removeTemplate() {
      const selectedNodes = this.gridApi.getSelectedNodes()
      if (selectedNodes) {
        const node = selectedNodes[0]
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + apiUrl + "/notebooks/" + node.data.id
        const token = "Bearer " + this.keycloak.token
        fetch(uri, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": token
          }
        })
            .then(async response => {
              if (response.ok) {
                this.refreshQuery()
              }
              else {
                const error = (await response.text() || response.statusText)
                return Promise.reject(error)
              }
            })
            .catch(error => {
              this.errorNotification(error)
            })
      }
    },
    refreshQuery() {
      getTemplates(this.keycloak.token)
      getClusters(this.keycloak.token)
    },
    newTemplate() {
      this.formTemplate.name = ""
      this.newTemplateVisible = true
    },
    onCellDoubleClicked(event) {
      const uri = "https" + "://" + zeppelinApi + "/#/notebook/" + event.data.id
      window.open(uri, '_blank');

    },
    errorNotification(msg) {
      console.log(msg)
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    },
    okNewTemplate() {
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/notebooks"
      const token = "Bearer " + this.keycloak.token
      const newTemplate = {
        name: this.formTemplate.name,
        path: "templates",
        cluster: this.formTemplate.cluster
      }
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(newTemplate),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
        .then(async response => {
          if (!response.ok) {
            const error = (await response.text() || response.statusText)
            return Promise.reject(error)
          }
          else {
            this.refreshQuery()
          }

        })
        .catch(error => {
          this.errorNotification(error)
        })
      this.newTemplateVisible = false
  }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>