import {defineStore} from 'pinia'

export const userStore = defineStore('users', {
    state: () => {
        return{
            users: [],
        }
    },
    actions:{
        changeUsers (payload) {
            let usersOptions = []
            payload.forEach(user =>{
                usersOptions.push({value:user.id,label:user.firstName + " " + user.lastName})
            })
            this.users = usersOptions.sort(( a, b ) => {
                if ( a.label < b.label ){
                    return -1;
                }
                if ( a.label > b.label ){
                    return 1;
                }
                return 0;
            })
        }
    }
})