import { createApp } from 'vue'
import App from './App.vue'
import Keycloak from "keycloak-js"
import {apiAuthorizeUrl, authEnabled} from "@/config/api"
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { createPinia } from 'pinia'

if (authEnabled) {
    const initOptions = {
        url: apiAuthorizeUrl, realm: 'axesor', clientId: 'zeppelin-front', onLoad: 'login-required'
    }
    const keycloak = new Keycloak(initOptions)

    keycloak
        .init({onLoad: initOptions.onLoad})
        .then((auth) => {
            if (!auth || ((!keycloak.realmAccess.roles.includes("zeppelin_user")) && (!keycloak.realmAccess.roles.includes("zeppelin_user_ro")))) {
                throw ("Authenticated Failed")
            } else
                createApp(App, {keycloak: keycloak})
                    .use(Antd)
                    .use(createPinia())
                    .mount('#app')

            // console.log(keycloak)
            setInterval(() => {
                keycloak
                    .updateToken(70)
                    .catch(() => {
                        console.log('Failed to refresh token')
                    })
            }, 6000)

        }).catch((error) => {
        console.log(error)
        console.log("Authenticated Failed")
    })

}
else {
    const keycloak = {
        token: "fake",
        subject: "fake",
        resourceAccess: {zeppelin: {roles: ""}}
    }
    createApp(App,{keycloak: keycloak})
        .use(Antd)
        .use(createPinia())
        .mount('#app')
}



