import {defineStore} from 'pinia'

export const clusterStore = defineStore('clusters', {
    state: () => {
        return{
            clusters: [],
            clustersOptions: []
        }
    },
    actions:{
        changeClusters (payload) {
            let clustersOptions = []
            payload.forEach(cluster =>{
                clustersOptions.push({value:cluster.name,label:cluster.name})
            })
            this.clustersOptions = clustersOptions
            this.clusters = payload
        }
    }
})