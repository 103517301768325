import {defineStore} from 'pinia'

export const templateStore = defineStore('templates', {
    state: () => {
        return{
            templates: [],
            templatesOptions: []
        }
    },
    actions:{
        changeTemplates (payload) {
            let templatesOptions = []
            payload.forEach(template =>{
                templatesOptions.push({value:template.id,label:template.name})
            })
            this.templatesOptions = templatesOptions.sort(( a, b ) => {
                if ( a.label < b.label ){
                    return -1;
                }
                if ( a.label > b.label ){
                    return 1;
                }
                return 0;
            })
            this.templates = payload
        }
    }
})