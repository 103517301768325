<template>
  <div id="SharingDashboard">

    <a-button class="button" type="primary" @click="this.newSharing()">
      New
    </a-button>

<!--    <a-popconfirm-->
<!--        title="Are you sure delete this sharing?"-->
<!--        ok-text="Yes"-->
<!--        cancel-text="No"-->
<!--        @confirm="removeSharing"-->
<!--    >-->
<!--      <a-button class="button" id="remove" type="primary">-->
<!--        Remove-->
<!--      </a-button>-->
<!--    </a-popconfirm>-->

    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>

    <a-switch v-model:checked="refresh"  @change="this.refreshAuto()"/>
    <a-spin :indicator="indicatorRefresh" :spinning="spinning"/>

    <ag-grid-vue
        id="table"
        style=" height: 700px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="sharings"
        :getRowStyle="getRowStyle"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <a-modal
        v-model:visible="newSharingVisible"
        :closable=false
        title="New Sharing"
        @ok="okNewSharing">
      <a-form
          v-model:model="formSharing"
          name="form_sharing"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
      >

        <a-form-item label="Corporation">
          <a-input v-model:value="formSharing['corporation']" />
        </a-form-item>

        <a-form-item label="Duration (minutes)">
          <a-input v-model:value="formSharing['duration']" />
        </a-form-item>

      </a-form>
    </a-modal>

    <a-modal
        v-model:visible="visibleToken"
        title="Data Sharing"
        width="800px"
        @ok="handleTokenOk">
        <template #footer>
          <a-button @click="handleTokenOk">OK</a-button>
        </template>
      <a-form
          :label-col="{ span: 2 }"
          :wrapper-col="{ span: 36 }"
      >
        <a-form-item label="URI">
          <a-input
              v-model:value="uri"

          >
            <template #prefix>
              <a-button @click="copyToClipboard(uri, 'URI copied to clipboard!')" >Copy</a-button>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="Token">
          <a-input
              v-model:value="token"
          >
            <template #prefix>
              <a-button @click="copyToClipboard(token, 'Token copied to clipboard!')" >Copy</a-button>
            </template>
          </a-input>
        </a-form-item>
      </a-form>
    </a-modal>

  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { LoadingOutlined } from '@ant-design/icons-vue';
import {apiUrl, TLSEnabled} from "@/config/api";
import {message, notification} from 'ant-design-vue';
import {reactive} from "vue";
import { h } from 'vue';

export default {
  components: {
    AgGridVue
  },
  props: ['keycloak','isActive'],
  setup() {

  },
  data() {
    return {
      refresh: true,
      spinning: true,
      refreshTimer: null,
      indicatorRefresh: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      }),
      corporation: reactive({}),
      headers: {
        authorization: "Bearer " + this.keycloak.token,
      },
      gridApi: null,
      columnApi: null,
      gridOptions: {
        enableCellTextSelection: true,
        pagination: false,
        paginationAutoPageSize: false,
        getRowId: (item) => {
          return item.data.name;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [
        {
          headerName: 'Name',
          width: 300,
          field: 'name',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Database',
          width: 200,
          field: 'database',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'Duration',
          width: 200,
          field: 'duration',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'Age',
          width: 200,
          field: 'age',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'Status',
          width: 150,
          field: 'status',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Owner',
          width: 150,
          field: 'owner',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }
      ],
      sharings: [],
      newSharingVisible: false,
      visibleToken: false,
      uri: '',
      token: '',
      formSharing: reactive({})
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setTimer()
        } else {
          this.cancelTimer()
        }
      },
    },
  },
  created() {
    this.refreshQuery()
    this.setTimer()
  },
  methods: {
    copyToClipboard(text, successMessage) {
      try {
        navigator.clipboard.writeText(text);
        message.success(successMessage);
      } catch (err) {
        message.error('Failed to copy!');
      }
    },
    convertToBigInt(value) {
      try {
        let bigIntNum = Number(value)
        console.log(bigIntNum)
        if (typeof bigIntNum === undefined || isNaN(bigIntNum))
          return ""
        else
          return String(bigIntNum)
      } catch (e) {
        return ""
      }
    },
    okNewSharing() {
      const tls = (TLSEnabled) ? "s" : ""

      const duration = this.convertToBigInt(this.formSharing.duration * 1000 * 60)
      const parameters = duration !== "" ? "?shutdownTimeout=" + duration : ""
      const uri = "http" + tls + "://" + apiUrl + "/sharing/intdb_" + this.formSharing.corporation + parameters
      console.log(uri)
      const token = "Bearer " + this.keycloak.token
      fetch(uri, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": token
        }
      })
          .then(async response => {
            if (!response.ok) {
              const error = (await response.text() || response.statusText)
              return Promise.reject(error)
            }
            else return response.json();
          })
          .then((responseJson) => {
            this.token = responseJson.token
            this.uri = responseJson.uri
            this.visibleToken = true
          })
          .catch(error => {
            this.errorNotification(error)
          })
      this.newSharingVisible = false
    },
    handleTokenOk() {
      this.visibleToken = false;
      this.refreshQuery()
    },
    newSharing() {
      this.formSharing.name = ""
      this.newSharingVisible = true
    },
    setTimer() {
      if (this.refreshTimer == null) {
        this.refreshTimer = setInterval(this.refreshQuery, 10000)
        this.spinning = true
      }
    },
    cancelTimer() {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
      this.spinning = false
    },
    refreshAuto() {
      if (this.refresh)
        this.setTimer()
      else
        this.cancelTimer()

    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'age', sort: 'asc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    getRowStyle: (params) => {
      switch (params.data.status) {
        case "Failed":
          return {'color': 'red'}
        case "Running":
          return {'color': 'green'}
        case "Pending":
          return {'color': 'orange'}
        default:
          return {'color': 'blue'}
      }
    },
    refreshQuery() {
      if (!this.refresh)
        this.cancelTimer()
      const token = this.keycloak.token
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + apiUrl + "/sharing"
      const tokenBearer = "Bearer " + token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": tokenBearer
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.sharings = responseJson
          })
          .catch((error) => {
            console.log(error)
          })
    },
    errorNotification(msg) {
      console.log(msg)
      notification.open({
        message: 'Error',
        description: msg,
        class: 'error-class',
      });
    }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
}

.ant-btn {
  color: #fff !important;
  border-color: #40a9ff !important;
  background: #40a9ff !important;
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>