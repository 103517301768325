<template>
  <div id="CloneDashboard">

    <a-form
        v-model:model="corporation"
        name="corporation"
        :wrapper-col="{ span: 2 }"
    >
      <a-form-item label="Corporation">
        <a-input v-model:value="corporation['id']" />
      </a-form-item>

    </a-form>

    <a-button class="button" type="primary" @click="this.cloneDatabase()">
      Clone
    </a-button>

    <a-button class="button" type="primary" @click="this.refreshQuery()">
      Refresh
    </a-button>

    <a-switch v-model:checked="refresh"  @change="this.refreshAuto()"/>
    <a-spin :indicator="indicatorRefresh" :spinning="spinning"/>

    <ag-grid-vue
        id="table"
        style=" height: 200px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="tasksDatabase"
        :getRowStyle="getRowStyle"
        :gridOptions="gridOptionsDatabase"
        @grid-ready="onGridReadyDatabase"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

    <ag-grid-vue
        id="table"
        style=" height: 500px;"
        class="ag-theme-alpine"
        :columnDefs="columns"
        :rowData="tasks"
        :getRowStyle="getRowStyle"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        :defaultColDef="defaultColDef">
    </ag-grid-vue>

  </div>

</template>

<script>

import {AgGridVue} from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { LoadingOutlined } from '@ant-design/icons-vue';
import {persistorApi, TLSEnabled} from "@/config/api";
import {reactive} from "vue";
import { h } from 'vue';

export default {
  components: {
    AgGridVue
  },
  props: ['keycloak','isActive'],
  setup() {

  },
  data() {
    return {
      refresh: true,
      spinning: true,
      refreshTimer: null,
      indicatorRefresh: h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      }),
      corporation: reactive({}),
      headers: {
        authorization: "Bearer " + this.keycloak.token,
      },
      gridApiDatabase: null,
      gridApi: null,
      gridOptionsDatabase: {
        onRowClicked: this.onDatabaseClicked,
        enableCellTextSelection: true,
        pagination: false,
        paginationAutoPageSize: false,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      gridOptions: {
        enableCellTextSelection: true,
        pagination: false,
        paginationAutoPageSize: false,
        getRowId: (item) => {
          return item.data.id;
        },
      },
      defaultColDef: {
        filter: true,
        wrapText: true,
        autoHeight: true,
        enableCellChangeFlash: true,
      },
      columns: [
        {
          headerName: 'Date',
          width: 200,
          field: 'created',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']}
        },
        {
          headerName: 'Database',
          width: 200,
          field: 'database',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Database cloned',
          width: 300,
          field: 'databaseCloned',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Table',
          width: 400,
          field: 'table',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Status',
          width: 100,
          field: 'status',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        },
        {
          headerName: 'Message',
          width: 500,
          field: 'msg',
          sortable: true,
          filter: 'agTextColumnFilter',
          filterParams: {buttons: ['reset', 'apply']},
        }
      ],
      roles: [],
      tasks: [],
      tasksDatabase: [],
      currentDatabase: ""
    }
  },
  watch: {
    isActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.setTimer()
        } else {
          this.cancelTimer()
        }
      },
    },
  },
  created() {
    this.refreshQuery()
    this.setTimer()
  },
  methods: {
    setTimer() {
      if (this.refreshTimer == null) {
        this.refreshTimer = setInterval(this.refreshQuery, 10000)
        this.spinning = true
      }
    },
    cancelTimer() {
      clearInterval(this.refreshTimer)
      this.refreshTimer = null
      this.spinning = false
    },
    refreshAuto() {
      if (this.refresh)
        this.setTimer()
      else
        this.cancelTimer()

    },
    onDatabaseClicked(event) {
      if (event.data.status === "DONE") {
        this.currentDatabase = event.data.databaseCloned
        this.getCloneTables()
      }
      else {
        this.tasks = []
        this.currentDatabase = ""
      }
    },
    onGridReadyDatabase(params) {
      this.gridApiDatabase = params.api;
      this.gridColumnApiDatabase = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'desc'}
      ];
      this.gridColumnApiDatabase.applyColumnState({state: defaultSortModel});
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      const defaultSortModel = [
        {colId: 'created', sort: 'desc'}
      ];
      this.gridColumnApi.applyColumnState({state: defaultSortModel});
    },
    getRowStyle: (params) => {
      switch (params.data.status) {
        case "FAIL":
          return {'color': 'red'}
        case "DONE":
          return {'color': 'green'}
        default:
          return {'color': 'blue'}
      }
    },
    cloneDatabase() {
      const token = this.keycloak.token
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/databases/clone"
      const tokenBearer = "Bearer " + token
      const cloneDatabase = {
        sourceDatabase: {database: "intdb_" + this.corporation.id},
        newDatabase: {database: "clone_intdb_" + this.corporation.id + this.generateRandomString(10)}
      }
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(cloneDatabase),
        headers: {
          'Content-Type': 'application/json',
          "Authorization": tokenBearer
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then(() => {
              this.refreshQuery()
          })
          .catch((error) => {
            console.log(error)
          })
    },
    getCloneTables() {
      if (this.currentDatabase !== "") {
        const token = this.keycloak.token
        const tls = (TLSEnabled) ? "s" : ""
        const uri = "http" + tls + "://" + persistorApi + "/tasks/users/" + this.keycloak.subject + "/task/clone_table?limit=80"
        const tokenBearer = "Bearer " + token
        fetch(uri, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": tokenBearer
          }
        })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
            })
            .then((responseJson) => {
              this.tasks = []
              const tasks = responseJson.results.filter((task) => {
                const payload = JSON.parse(task.payload)
                return payload.newTable.database === this.currentDatabase
              })          
              tasks.forEach(task => {
                const payload = JSON.parse(task.payload)
                let msg = (task.status === "DONE") ? "Cloned" : task.msg
                this.tasks.push({
                  id: task.id,
                  created: task.created,
                  database: payload.sourceTable.database,
                  databaseCloned: payload.newTable.database,
                  table: payload.sourceTable.table,
                  status: task.status,
                  msg: msg
                })
              })

            })
            .catch((error) => {
              console.log(error)
            })
      }
    },
    refreshQuery() {
      if (!this.refresh)
        this.cancelTimer()
      const token = this.keycloak.token
      const tls = (TLSEnabled) ? "s" : ""
      const uri = "http" + tls + "://" + persistorApi + "/tasks/users/" + this.keycloak.subject + "/task/clone_database?limit=3"
      const tokenBearer = "Bearer " + token
      fetch(uri, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": tokenBearer
        }
      })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((responseJson) => {
            this.tasksDatabase = []
            responseJson.results.forEach(task =>{
              const payload = JSON.parse(task.payload)
              let msg = (task.status == "DONE") ? "Cloning" : task.msg
              this.tasksDatabase.push({
                id: task.id,
                created: task.created,
                database: payload.sourceDatabase.database,
                databaseCloned: payload.newDatabase.database,
                table: "",
                status: task.status,
                msg: msg
              })
            })
            this.getCloneTables()
          })
          .catch((error) => {
            console.log(error)
          })
    },
    generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
      }
  }

}
</script>

<style>

#table {
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  margin-right: 20px;
}

.ant-btn {
  color: #fff !important;
  border-color: #40a9ff !important;
  background: #40a9ff !important;
  margin-right: 20px;
}

html::-webkit-scrollbar {
  display: none;
}

</style>